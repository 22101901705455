import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Admin from "./Components/Admin/Admin";
import Login from "./Components/Login/Login";

function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem('adminAccessToken');
  return isAuthenticated ? children : <Navigate to="/" />;
}

function App() {
  return (
    <>

      <Router>
        <Routes>
          {/* Protected Admin Route */}
          <Route path="/admin/*" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
 
          {/* Login Route */}
          <Route path="/" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
