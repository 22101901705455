import React, { useState } from "react";
import "./admin.scss";
import { Tabs, Tab } from "react-bootstrap";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import Nfts from "./Nfts/Nfts";
import Collections from "./Collections/Collections";
import Navbar from "../Navbar/Navbar";
import Dropdown from 'react-bootstrap/Dropdown';
import Loader from "../../utils/loader";

function Admin() {

  const navigate = useNavigate();
  const location = useLocation();

  // Determine the active tab based on the current path (e.g., "/admin/nfts" -> "nfts")
  const activeKey = location.pathname.split("/")[2] || "nfts";

  const handleSelect = (key) => {
    navigate(`/admin/${key}`);
  };

  const [loading, setLoading] = useState(true);

  const [apply, setApply] = useState(false);
  const [featuredFilter, setFeaturedFilter] = useState(false);
  const [trendingFilter, setTrendingFilter] = useState(false);
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);
  const [clear, setClear] = useState(false);
  const [search, setSearch] = useState(null);


  // console.log(
  //   apply,
  //   featuredFilter,
  //   trendingFilter,
  //   publishedFilter,
  //   unpublishedFilter,
  //   clear,
  //   "all filtersss"
  // );


  const handleApply = () => {
    // Handle the apply action (e.g., send the filter state to an API or apply filters)
    setApply(true);
    console.log('Apply Filters:', {
      featuredFilter,
      trendingFilter,
      publishedFilter,
      unpublishedFilter,
    });
  };

  const handleClear = () => {
    // Reset all checkboxes to their initial state
    setFeaturedFilter(false);
    setTrendingFilter(false);
    setPublishedFilter(false);
    setUnpublishedFilter(false);
    setClear(true);
    setApply(false);
  };


  const handleFilterChange = (filter) => {
    setFeaturedFilter(filter === 'featured');
    setTrendingFilter(filter === 'trending');
    setPublishedFilter(filter === 'published');
    setUnpublishedFilter(filter === 'unpublished');
  };


  return (
    <>
      {loading && <Loader />}
      <Navbar />
      <div className="Headdiv">
        <section className="Admin_main">
          <div className="admin">
            <div className="tab_header">
              <div>
                <Tabs activeKey={activeKey} onSelect={handleSelect} id="admin-tabs">
                  <Tab eventKey="nfts" title="All sNFTs" />
                  <Tab eventKey="collections" title="Collections" />
                </Tabs>
              </div>

              <div className="leftside">
                <div className="inputside">
                  <input type="text" id="fname" name="fname" placeholder='Search' value={search} onChange={(e) => setSearch(e?.target?.value)} />
                  <svg className="svgdiv" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M10.625 10.625L14.875 14.875M7.08333 12.0417C4.34492 12.0417 2.125 9.82175 2.125 7.08333C2.125 4.34492 4.34492 2.125 7.08333 2.125C9.82175 2.125 12.0417 4.34492 12.0417 7.08333C12.0417 9.82175 9.82175 12.0417 7.08333 12.0417Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div className="dropside">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Filter
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M13.2812 9.03125C13.2812 9.17215 13.2253 9.30727 13.1257 9.4069C13.026 9.50653 12.8909 9.5625 12.75 9.5625H4.25C4.1091 9.5625 3.97398 9.50653 3.87435 9.4069C3.77472 9.30727 3.71875 9.17215 3.71875 9.03125C3.71875 8.89035 3.77472 8.75523 3.87435 8.6556C3.97398 8.55597 4.1091 8.5 4.25 8.5H12.75C12.8909 8.5 13.026 8.55597 13.1257 8.6556C13.2253 8.75523 13.2812 8.89035 13.2812 9.03125ZM15.4062 5.3125H1.59375C1.45285 5.3125 1.31773 5.36847 1.2181 5.4681C1.11847 5.56773 1.0625 5.70285 1.0625 5.84375C1.0625 5.98465 1.11847 6.11977 1.2181 6.2194C1.31773 6.31903 1.45285 6.375 1.59375 6.375H15.4062C15.5471 6.375 15.6823 6.31903 15.7819 6.2194C15.8815 6.11977 15.9375 5.98465 15.9375 5.84375C15.9375 5.70285 15.8815 5.56773 15.7819 5.4681C15.6823 5.36847 15.5471 5.3125 15.4062 5.3125ZM10.0938 11.6875H6.90625C6.76535 11.6875 6.63023 11.7435 6.5306 11.8431C6.43097 11.9427 6.375 12.0779 6.375 12.2188C6.375 12.3596 6.43097 12.4948 6.5306 12.5944C6.63023 12.694 6.76535 12.75 6.90625 12.75H10.0938C10.2346 12.75 10.3698 12.694 10.4694 12.5944C10.569 12.4948 10.625 12.3596 10.625 12.2188C10.625 12.0779 10.569 11.9427 10.4694 11.8431C10.3698 11.7435 10.2346 11.6875 10.0938 11.6875Z" fill="#0A0A0A" />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="drop_menu">
                        {location.pathname !== "/admin/collections" && (
                          <>
                            <div className="innner_drop">
                              <label className="container">
                                Featured
                                <input
                                  type="checkbox"
                                  checked={featuredFilter}
                                  onChange={() => handleFilterChange('featured')}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="innner_drop">
                              <label className="container">
                                Trending
                                <input
                                  type="checkbox"
                                  checked={trendingFilter}
                                  onChange={() => handleFilterChange('trending')}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </>
                        )}
                        <div className="innner_drop">
                          <label className="container">
                            Published
                            <input
                              type="checkbox"
                              checked={publishedFilter}
                              onChange={() => handleFilterChange('published')}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="innner_drop new_drop">
                          <label className="container">
                            Unpublished
                            <input
                              type="checkbox"
                              checked={unpublishedFilter}
                              onChange={() => handleFilterChange('unpublished')}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="button_div">
                          <button className="clearbuton" onClick={handleClear}>
                            Clear
                          </button>
                          <button className="applybutton" onClick={handleApply}>
                            Apply
                          </button>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>


            <div className="admin-content">
              <Routes>
                {/* Redirect /admin to /admin/nfts */}
                <Route path="/" element={<Navigate to="nfts" replace />} />
                <Route path="nfts" element={<Nfts
                  apply={apply}
                  featuredFilter={featuredFilter}
                  trendingFilter={trendingFilter}
                  publishedFilter={publishedFilter}
                  unpublishedFilter={unpublishedFilter}
                  clear={clear}
                  setApply={setApply}
                  search={search}
                  setLoading={setLoading}
                />} />
                <Route path="collections" element={<Collections
                  apply={apply}
                  featuredFilter={featuredFilter}
                  trendingFilter={trendingFilter}
                  publishedFilter={publishedFilter}
                  unpublishedFilter={unpublishedFilter}
                  clear={clear}
                  setApply={setApply}
                  search={search}
                  setLoading={setLoading}
                />} />
              </Routes>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Admin;
